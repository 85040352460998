/* eslint-disable no-console,no-undef, camelcase, no-constant-condition */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import {
  getOrientation,
  resetOrientation,
  checkImage,
  fetchApi,
} from "../../../utils/methods";
import { imageErrorMessage, ImageUrl } from "../../../utils/constants";
import { withTranslation } from "react-i18next";
import {
  Layout,
  ChallengeHeading,
  Container,
  InputContainer,
  StyledInputV2,
  StyledLabel,
  ButtonContainer,
  EditorContainer
} from "../../CreateEvent/style";
import StepsHeading from "../../CreateEvent/stepNames";
import { Main } from "../../AdminTeamCreation/styles";
import Editor from "../../CKEditor";
import HoverButton from "../../common/HoverButton";
import CommonDropdown from "../../common/CommonDropDown/CommonDropdown";
import CommonButton from "../../common/CommonButton/CommonButton";
import { Arrows, LaunchEventIcon, NextArrows, closeIcon } from "../../../utils/icons";
import { AddCompanyExemplifiesValues } from "../../../redux/constants/apiConstants";
import { Border } from "../../Journeys/styles";
import { withRouter } from 'react-router-dom';
import {ShowImage} from './styles';

class EditCoreValues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imgSrc: ImageUrl+"/"+props.history?.location?.state?.image,
      imgSrcOriginal: props.history?.location?.state?.image,
      title: props.history?.location?.state?.title,
      description: props.history?.location?.state?.description?props.history?.location?.state?.description:"",
      imageName: "",
      eventPointKey: props.history?.location?.state?.points,
      eventPointValue: props.history?.location?.state?.points,
      status: props.history?.location?.state?.status,
      recognitionId: props.history?.location?.state?.id,
      color: props.history?.location?.state?.color,
      so:false,
      step:1,
      buttonStatus: false,
      type: props.history?.location?.state?.type,
      pointsArray : [
        { value: 5, text: "5 Pts" },
        { value: 10, text: "10 Pts" },
        { value: 15, text: "15 Pts" },
        { value: 20, text: "20 Pts" },
        { value: 25, text: "25 Pts" },
        { value: 30, text: "30 Pts" },
        { value: 35, text: "35 Pts" },
        { value: 40, text: "40 Pts" },
        { value: 45, text: "45 Pts" },
        { value: 50, text: "50 Pts" }
      ]
    };
  }

  onChange = (e) => {
    let file = e.target.files[0];
    let fileArr = e.target.files;
    if (e.target.files[0]) {
      if (checkImage(fileArr)) {
        if (file?.size / 1000000 <= 20) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          const array = document
            .getElementById("event-upload-file")
            .value.split("\\");
          reader.onloadend = () => {
            this.setState({
              imgSrc: reader.result,
              imageName: array[array.length - 1],
            });
            getOrientation(file, (or) => {
              resetOrientation([reader.result], or, (baseImage) => {
                this.setState({
                  imgSrc: baseImage,
                });
              });
            });
          };
        } else {
          toast.error("Please select image file less than 20MB");
          document.getElementById("event-upload-file").value = "";
        }
      } else {
        toast.error(imageErrorMessage);
        document.getElementById("event-upload-file").value = "";
      }
    }
  };

  onChangeInput = (e) => {
    if (e.target.value.charAt(0) !== " ") {
      this.setState({
        [e.target.name]: e.target.value,
      });

      if (e.target.name === "eventLink") {
        this.isUrl(e.target.value);
      }
    }
  };

  removePhoto = () => {
    this.setState({
      imgSrc: "",
      imageName: "",
    });
  };

  onSelectPoints = (name) => {
    this.setState({
      [name]: name.value,
      eventPointKey: name.key,
      eventPointValue: name.value,
    });
  };

  saveData = () => {
    const {
      title,
      description,
      eventPointValue,
      imgSrcOriginal,
      recognitionId,
      status
    } = this.state;
    const {companyInfo}= this.props;
    const RecognitionDetails = {
      company_id: companyInfo?.id,
      is_deleted: [],
      recognitions: [
        {
          core_value: title,
          description: description,
          id: recognitionId,
          image: imgSrcOriginal,
          point: eventPointValue,
          status: status
        }
      ]
    };
    
    this.PostRecognition(RecognitionDetails);
  };

  PostRecognition = async(payload)=>{
    try{
      const res = await fetchApi(AddCompanyExemplifiesValues, "POST",payload);
      if(res?.data?.message){
        toast.error(res.data.message);
        this.setState({
          buttonStatus: false,
        });
      }else{
        toast.success(res.data[0]);
        this.props.history.push(`/company/recognition/addcorevalues`);
      }
    }catch(error){
      toast.error(error)
    }
  }

  onChangeDescription = (evt) => {
    const newContent = evt.getData();
    const trimmedString = newContent.replace(/\s|&nbsp;/g, "");
    trimmedString.length === 0 ||
    trimmedString.length === 7 ||
    trimmedString == "<p></p><p></p>"
      ? this.setState({ description: "" })
      : this.setState({ description: newContent });
  };

  updateStep = (value) => {
    this.setState({ step: value, buttonClickScrollTop: true });
  };

  nextStep = (value) => {
    this.setState({
      step: value,
      buttonClickScrollTop: true,
      buttonStatus: false,
    });
  };

  redirect = () => {
    this.props.history.goBack();
  };

  renderHeading = () => (
    <ChallengeHeading color={"#005c87"}>
      <div style={{marginRight:"12px"}}>
        <HoverButton
          title={this.props.t("Close")}
          width="24px"
          height="24px"
          svgPath={closeIcon()}
          onClick={this.redirect}
          activeColor={"#007AB1"}
          fillOpacity={1}
        />
      </div>
      {this.state.type=="launch"?this.props.t("Launch Company Core Value"):this.props.t("Edit Company Core Value")}
    </ChallengeHeading>
  );

  stepOneForm = () => {
    const {
      title,
      description,
      step,
      color
    } = this.state;
    const { t } = this.props;
    return (
      <Layout>
        <div className="main" >
          {this.renderHeading()}
          <Container color="#005C87">
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} type="core-value" padding="20px 20px 265px 20px"/>
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName" style={{color:"#005c87"}}>{this.props.t("Core value introduction")}</div>
              </div>
              <div className="formBody">
                <InputContainer>
                  <StyledLabel color={"#005c87"}>{this.props.t("Title of the core value")}{<span>*</span>}</StyledLabel>
                  <StyledInputV2
                    placeholder={t("Write title here...")}
                    name="title"
                    value={title}
                    maxLength="100"
                    style={{background:"white"}}
                    pColor={"#649bb3"}
                    color="#005c87"
                    border={"1px solid rgba(0, 122, 177, 0.30)"}
                    disabled={1}
                  />
                </InputContainer>
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px'}}/>

                <StyledLabel color={"#005c87"} style={{marginTop:"0px"}}>{this.props.t("Core value icon")}{<span>*</span>}</StyledLabel>
                <ShowImage background={color}>
                  <div className="image">
                    <img alt="profilre-img" src={this.state.imgSrc}></img>
                  </div>
                </ShowImage>
                <Border style={{background:'rgba(0, 92, 135, 0.10)',margin:'20px 0px'}}/>
                <InputContainer>
                  <StyledLabel style={{marginTop:"0px"}} color={"#005c87"}>{this.props.t("About the core value")}</StyledLabel>
                  <EditorContainer>
                    <Editor
                      content={description}
                      onChange={this.onChangeDescription}
                    />
                  </EditorContainer>
                </InputContainer>
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  stepTwoForm = () => {
    const {
      step,
      eventPointValue,
      pointsArray
    } = this.state;
    return (
      <Layout>
        <div className="main">
          {this.renderHeading()}
          <Container color={"#005c87"}>
            <div className="stepsNameHighlight">
              <StepsHeading stepCount={step} type="core-value" padding="20px 20px 265px 20px"/>
            </div>
            <div className="form">
              <div className="heading">
                <div className="step">{step}</div>
                <div className="headingName">
                  {this.props.t("How would you like to customize your core value?")}
                </div>
              </div>
              <div className="formBody">
                <CommonDropdown
                  tileContainerStyle={{ width: "100%",padding:"20px 0px 20px 0px" }}
                  dropdownStyle={{ top: "90px" }}
                  labelText={this.props.t("Core value point")}
                  isRequired={true}
                  dropdownOpen={this.state.so}
                  placeholder={this.props.t("Select Point Value")}
                  title={
                    eventPointValue?eventPointValue+" Points":+"No Points"
                  }
                  id="dropdown-recurring"
                  onClick={() => this.setState({ so: !this.state.so })}
                  data={pointsArray}
                  onSelect={this.onSelectPoints}
                  active={eventPointValue}
                  onSelectParameter={["eventPointValue", "value", "key"]}
                  valueString={"Points"}
                  itemValue={true}
                  valueIndex={1}
                  labelMargin={"0px"}
                />        
              </div>
            </div>
          </Container>
        </div>
      </Layout>
    );
  };

  render() {
    const {
      title,
      imgSrc,
      step,
      buttonStatus
    } = this.state;
    return (
      <React.Fragment>
        {step === 1
          ? this.stepOneForm()
          : step === 2
            ? this.stepTwoForm()
            : null}
        {step === 1 ? (
          <ButtonContainer>
            <Main>
              <CommonButton
                btnType={"squareIcon"}
                onClick={() => this.updateStep(2)}
                disabled={!title ||!imgSrc}
                title={this.props.t("Next")}
                styles={{color:"#007AB1"}}
                nextIcon={NextArrows("white")}
              />
            </Main>
          </ButtonContainer>
        ) :
          step === 2 && (
            <ButtonContainer>
              <div className="wrapper">
                <CommonButton
                  btnType={"squareIcon"}
                  styles={{color:"white",textColor:"#007AB1",border:"#007AB1",width:"200px", marginLeft:"0px",display:"flex",justifyContent:"center",alignItems:"center"}}
                  onClick={() => this.nextStep(1)}
                  title={this.props.t("Go Back")}
                  icon={Arrows()}
                />
                <CommonButton
                  btnType={"squareIcon"}
                  styles={{color:"#007AB1", marginAuto:'auto'}}
                  onClick={!buttonStatus? () => this.saveData():''}
                  title={this.props.t("Submit")}
                  nextIcon={LaunchEventIcon()}
                />
              </div>
            </ButtonContainer>
          )}
      </React.Fragment>
    );
  }
}

EditCoreValues.propTypes = {
  history: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
  t: PropTypes.func,
  companyInfo: PropTypes.object
};

export default (withRouter(withTranslation()(EditCoreValues)));
